<template>
  <section class="collapse-section">
    <p class="collapse__title main__title">
      Вопросы и ответы
    </p>
    <div class="container">
      <div class="collapse-section__item">
        <button
          v-b-toggle.collapse-1
          class="collapse-section__button"
        >
          <span>Как производится осмотр акваторий?</span>
          <svg
            focusable="false"
            class="collapse-img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              stroke="none"
              stroke-width="5px"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                transform="translate(1.000000, 1.000000)"
                stroke="#f4c744"
              >
                <path d="M0,11 L22,11" />
                <path d="M11,0 L11,22" />
              </g>
            </g>
          </svg>
        </button>
        <b-collapse
          id="collapse-1"
          class="collapse-section__content"
        >
          <b-card>
            <p>
              Периодические технические осмотры сооружений обязаны проделываться в соответствии с Положением о<br>
              проведении периодических техосмотров сооружений предприятий
            </p>
          </b-card>
        </b-collapse>
      </div>
      <div class="collapse-section__item">
        <button
          v-b-toggle.collapse-2
          class="collapse-section__button"
        >
          <span>Нужен ли техосмотр на грузовой прицеп и полуприцеп?</span>
          <svg
            focusable="false"
            class="collapse-img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              stroke="none"
              stroke-width="5px"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                transform="translate(1.000000, 1.000000)"
                stroke="#f4c744"
              >
                <path d="M0,11 L22,11" />
                <path d="M11,0 L11,22" />
              </g>
            </g>
          </svg>
        </button>
        <b-collapse
          id="collapse-2"
          class="collapse-section__content"
        >
          <p>
            Пройти техосмотр грузовика (массой более 3,5 тонн) прицепа и полуприцепа необходимо ежегодно, начиная с первой регистрации.
          </p>
        </b-collapse>
      </div>
      <div class="collapse-section__item">
        <button
          v-b-toggle.collapse-3
          class="collapse-section__button"
        >
          <span>Какие водные транспортные средства нужно регистрировать?</span>
          <svg
            focusable="false"
            class="collapse-img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              stroke="none"
              stroke-width="5px"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                transform="translate(1.000000, 1.000000)"
                stroke="#f4c744"
              >
                <path d="M0,11 L22,11" />
                <path d="M11,0 L11,22" />
              </g>
            </g>
          </svg>
        </button>
        <b-collapse
          id="collapse-3"
          class="collapse-section__content"
        >
          <p>
            Гребные лодки (надувные ПВХ резиновые, деревянные, пластиковые, металлические), байдарки, надувные "бананы" (более 250 см), лодки с мотором (любого типа и мощности) независимо от длины
          </p>
        </b-collapse>
      </div>
      <div class="collapse-section__item">
        <button
          v-b-toggle.collapse-4
          class="collapse-section__button"
        >
          <span>В каком состояние должен быть автомобиль для получения протокола о техническом состоянии автомобиля?</span>
          <svg
            focusable="false"
            class="collapse-img"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              stroke="none"
              stroke-width="5px"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                transform="translate(1.000000, 1.000000)"
                stroke="#f4c744"
              >
                <path d="M0,11 L22,11" />
                <path d="M11,0 L11,22" />
              </g>
            </g>
          </svg>
        </button>
        <b-collapse
          id="collapse-4"
          class="collapse-section__content"
        >
          <p>
            Для проведения обязательного техосмотра, принимается полностью укомплектованный автомобиль, заправлен всеми эксплуатационными жидкостями транспортное средство, отсутствие на нем загрязнений, которые могут повлиять на результаты проверки технического состояния.
          </p>
        </b-collapse>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeCollapse'
}
</script>
